import type {
  ICaseItemDetailedReq,
  IFilterFormContent,
  IInventoryItemDetailedReq,
  IInventoryItemDetailedWithTranslateReq,
  MarketSortingTypes,
} from '@dev-fast/types';

export class GetFullSkinData {
  static readonly type = '[Skins] Get full data';

  constructor(public params: ICaseItemDetailedReq) {}
}

export class GetCaseItemDetails {
  static readonly type = '[Skins] Get item details';

  constructor(public params: IInventoryItemDetailedReq) {}
}
export class GetCaseItemDetailsWithTranslate {
  static readonly type = '[Skins] Get item details with translate';

  constructor(public params: IInventoryItemDetailedWithTranslateReq) {}
}
export class GetAvailabilityItemInCases {
  static readonly type = '[Skins] Get item availability in cases';

  constructor(public params: { name?: string; shortName?: string; skin?: string }) {}
}

export class GetItemAvailabilityOnThenMarket {
  static readonly type = '[Skins] Get item availability in market';

  constructor(public params: IFilterFormContent<MarketSortingTypes>) {}
}
